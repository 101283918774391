<!--
 * @Description:
 * @Author:
 * @Date: 2022-11-22 09:47:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-19 16:41:32
-->
<template>
  <div style="height: 100%">
    <!-- 卡片视图 -->
    <el-card style="height: 100%">
      <!-- 顶部面包屑导航 -->
      <breadcrumb-nav>
        <template v-slot:firstMenu>系统管理</template>
        <template v-slot:secondMenu>主题设置</template>
      </breadcrumb-nav>
      <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
        主题设置
      </div>
      <div style="marginTop: 30px;fontSize: 16px">主题颜色</div>
      <div style="display: flex;marginTop: 15px;fontSize: 12px">
        <template v-for="(item,index) in colorList" >
          <div :key="index">
            <div class="color_box" :style='{backgroundColor: item.color}'  @click="changeColor(index,item.color)">
              <i v-if="colorIndex == index" class="el-icon-check"></i>
            </div>
            <div style="width: 50px;textAlign: center;marginTop: 5px">{{item.text}}</div>
          </div>
        </template>
        <div>
          <el-color-picker class="color-picker" v-model="color1" @change="selectChange"></el-color-picker>
          <div style="width: 50px;textAlign: center">自定义</div>
        </div>
      </div>
      <div style="marginTop: 25px;fontSize: 16px">平台名称</div>
      <el-input v-model="input" placeholder="请输入内容" style="width: 500px;marginTop: 10px"></el-input>
      <div class="imageText">logo，建议尺寸314*125px，支持PNG、JPEG、JPG格式，大小不超过300kb</div>
      <div class="imageBox">
        <img style="width: 100%;height:100%;borderRadius: 50%;" :src="imageUrl" alt="" >
        <div style="margin:auto;width: 80px;marginTop: 10px">
          <el-upload
                      :action="uploadUrl"
                      :headers="imgUploadHeaders"
                      :on-success="handleImgUploadSuccess"
                      :show-file-list="showList"
                      >
                <el-button size="small" type="primary">更换头像</el-button>
              </el-upload>
        </div>
      </div>
      <div class="buttonBox">
        <el-button size="small" type="primary" style="marginRight: 30px">取消</el-button>
        <el-button size="small" type="primary" @click="saveTheme">保存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>

  // 导入公共组件
  import BreadcrumbNav from "../common/BreadcrumbNav";
  import { getStyleRequest,getQueryStyleRequest } from 'network/rights'

  export default {
    name: "Roles",
    components:{
      BreadcrumbNav,
    },
    data() {
      return {
        showList: false,
        input: '',
        uploadUrl: this.api.imageUrl+ "/trade/purchaseorder/uploadImg",
        imgUploadHeaders: { // 上传图片控件的header
          Authorization: localStorage.getItem('token')
        },
        imageUrl: '',
        uploadImageUrl: '',
        imageLists: [],
        colorIndex: 0,
        themeColor: '',
        color1: '',
        colorList: [
          {
           label: 0,
           color: '#616FFE',
           text: '官方色'
          },
          {
           label: 1,
           color: '#008000',
           text: '绿色'
          },
          {
           label: 2,
           color: '#DC143C',
           text: '红色'
          }
        ],
        sysStyleId: '',

      }
    },
    created() {
      this.getTheme()
    },
    methods: {
      changeColor(index,color){
        this.colorIndex = index
        this.themeColor = color
      },
      selectChange(value){
        this.colorIndex = null
        this.themeColor = value
      },
      getTheme(){
        getQueryStyleRequest({
          // enterpriseId: localStorage.getItem('enterpriseId')
        }).then(res=>{
          console.log(res);
          this.imageUrl = this.api.imageUrl+ '/trade'+res.data.data.logoPath
          this.sysStyleId = res.data.data.sysStyleId
          this.input = res.data.data.platformName
          this.themeColor = res.data.data.color
          if(this.colorList.some((item) => item.color ==res.data.data.color)){
            for(let i =0;i<this.colorList.length;i++){
              if(this.colorList[i].color==res.data.data.color){
                this.colorIndex = i
              }
            }
          }else{
            this.colorIndex = null
            this.color1 = res.data.data.color
          }
        })
      },
      saveTheme(){
        getStyleRequest({
          sysStyleId: this.sysStyleId,
          logoPath: this.uploadImageUrl,
          enterpriseId: localStorage.getItem('enterpriseId'),
          platformName: this.input,
          color: this.themeColor
        }).then(res=>{
          console.log(res);
        })
        document.documentElement.style.setProperty("--theme_bg_color", this.themeColor);
      },

        // 监听图片上传成功
        handleImgUploadSuccess(response) {
          let reg2 = new RegExp(",");
          console.log(response.data.replace(reg2,''));
          this.imageUrl =this.api.imageUrl+ '/trade/images/'+ response.data.replace(reg2,'')
          this.uploadImageUrl = this.api.imageUrl+ '/trade/images/'+response.data.replace(reg2,'')
        },
    }
  }
</script>

<style scoped>
.color_box{
  width: 50px;
  height: 50px;
  font-size: 28px;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
  margin-right: 10px;
}

.color-picker{
  width: 50px;
  height: 50px;
}
::v-deep .el-color-picker__trigger{
  width: 50px;
  height: 50px;
  padding: 0px;
  border: 0px;
}
.imageBox{
  width: 100px;
  /* height: 100px; */
  border-radius: 50%;
  margin-top: 10px;
}
.imageText{
  margin-top: 25px;
  font-size: 16px;
}
.buttonBox{
  margin-top: 80px;
  width: 90%;
  display: flex;
  justify-content: flex-end;
}
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}


</style>
